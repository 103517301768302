body {
  margin: 0;
  font-family: 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Arial;
}

.app {
  padding: 30px;
  height: 100vh;
}

h1 {
  margin-bottom: 7px;
  background-image: -webkit-linear-gradient(to right, #ffffff, #eeeeee);  /* Chrome 10-25, Safari 5.1-6 */
  background-image: linear-gradient(to right, #ffffff, #eeeeee); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3em;
}

#logo h1, #logo img {
  display: inline-block;
}

#logo img {
  height: 2.2em;
  margin-right: 5px;
}

.todo-list{
  background: #0f0c29;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 4px;
  padding: 15px;
  max-width: 400px;
}

.todo{
  background: #fff;
  box-shadow: 1px 1px 1px rgba(0,0,0, 0.1);
  padding: 3px 10px;
  font-size: 18px;
  margin-bottom: 6px;
  border-radius: 3px;
  display:flex;
  flex-direction:row;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1.5em;
  padding-left: 32px;
  position: relative;
}

.input{
  width: 100%;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  padding: 1rem;
  height: 2.25em;
  font-size: 24px;
}

.input::placeholder {
  opacity: 0.5;
}

.input:focus::placeholder {
  opacity: 0;
}

.todo .icon {
  position:absolute;
  left: 6px;
  top: 7px;
}

.todo .icon.hover, 
.todo:hover .icon.nohover {
  opacity: 0;
}

.todo:hover .icon.hover, 
.todo icon.nohover {
  opacity: 1;
}

.todo:hover {
  cursor: pointer;
}

#add-todo:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px #421d6c;
}